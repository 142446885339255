






import Vue from 'vue'
import ContactUsPage from './ContactUs.vue'
export default Vue.extend({
    name: 'ContactUsIndex',
    components: {
        ContactUsPage
    },
})
