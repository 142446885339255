






import Vue from 'vue';
import ContactUs from '../../components/contactus/ContactUs.vue';
export default Vue.extend({
    name: 'ContactUsPage',
    components: {
        ContactUs
    },
    metaInfo(){
      return  {
      title: "Contact Us - Our Nation Express",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Contact Our Nation Express at phone number 876-223-1030 / 876-223-0995, or ournationexpress@gmail.com"
        }
      ]
    };
    }
})
